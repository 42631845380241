<template>
  <div>
    <div class="title">付款管理</div>
    <!-- 搜索 -->
    <div class="search">
      <span>任务名称：</span>
      <a-input style="width:200px" v-model="queryParams.title" placeholder="请输入任务名称"/>
      <div class="table-search-submitButtons">
        <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
        <a-button style="margin-left: 8px;" icon="reload" @click="resetSearchEnterprise">重置</a-button>
				<a-button style="margin-left: 8px;" v-if="taxPayPermission" icon="plus" @click="taxPayModal = true">批量付款</a-button>
      </div>
    </div>
    <div class="content">
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="raskTable"
        :columns="raskColumns"
        :data="raskData"
        @change="onRaskChange"
        :rowKey="(record) => record.id"
      >
        <span slot="payStatus" slot-scope="text">
          <div class="table-point" v-if="text==='10'"><span></span> 待审核</div>
          <div class="table-point" v-if="text==='20'"><span></span> 审核成功</div>
          <div class="table-point" v-if="text==='21'"><span></span> 付款处理中</div>
          <!-- <div class="table-point" v-if="text==='30'"><span></span> 付款成功</div> -->
          <div class="table-point" v-if="text==='30'"><span></span> 处理完成</div>
          <div class="table-point" v-if="text==='50'"><span></span> 未达到支付条件</div>
          <div class="table-point" v-if="text==='60'"><span></span> 取消支付</div>
          <div class="table-point" v-if="text === '40'"><span style="background:#52C41A"></span> 待提交</div>
        </span>
        <span slot="action" slot-scope="text, record">
          <!-- <a @click="cancelRask(record)">审核</a>
          <a-divider type="vertical" />
          <a @click="fuheRask(record)">复核</a>
          <a-divider type="vertical" /> -->
          <a @click="raskDetail(record)" v-if="record.payAmount!==0">详情</a>
        </span>
      </s-table>
    </div>
		
		<!-- 直接付款 -->
		<a-modal
			title="创建付款单-上传付款名单及任务成果"
		  width="700px"
		  :visible="taxPayModal"
		  :footer="null"
		  @cancel="taxPayModal = false"
		  destroyOnClose
		>
		  <div class="search">
				<span>选择任务<span style="color: red;">*</span>：</span>
				<div>
					<a-select style="width: 400px;" 
					:options="taxPay.taskList"
					show-search 
					v-model="taxPay.taskId"
					@change="taskSelect">
					</a-select>
				</div>
			</div>
			<div class="search">
				<span class="span">结算周期<span style="color: red;">*</span>：</span>
				<div class="tag">
				  <a-range-picker @change="onChangeDate" />
				</div>
			</div>
			<div class="search">
				<span>付款名单<span style="color: red;">*</span>：</span>
				<div>
					<a-upload
					    :file-list="taxPay.fileList"
					    :multiple="false"
							accept=".xlsx, .xls"
							name="file"
					    :action="taxPay.uploadUrl"
					    :headers="headers"
							:data="taxPay.data"
					    @change="handleChangeFile"
					  >
					    <a-button>
					      <upload-outlined></upload-outlined>
					      点击选择名单文件
					    </a-button>
					  </a-upload>
				</div>
			</div>
			<div style="color: red; line-height: 28px; width: 90%; margin: 10px auto 0">
				<a-icon type="link" />
				上传说明：<a
						  href="https://tax-trade.oss-cn-chengdu.aliyuncs.com/template/%E9%95%BF%E6%9C%9F%E4%BB%BB%E5%8A%A1%E6%89%B9%E9%87%8F%E7%A1%AE%E8%AE%A4%E6%A8%A1%E6%9D%BFv2.0.xlsx"
						  target="blink">下载模板</a><br/>
				1.仅允许导入“.xlsx, .xls”格式文件！<br/>
				2.文件上传时系统会对人员进行验证，等待时间较长，请耐心等待.
			</div>
			<div class="pl-total">
			 <span class="total-ze">已识别：<span style="color: green;">{{ taxPay.successSize }} </span>人</span>
			 <span style="margin-left: 20px;" class="total-ze">支付金额：<span style="color: green;">{{ taxPay.successAmount }} </span>元</span>
			 <span style="margin-left: 20px;" class="total-ze">总金额(含服务费)：<span style="color: green;">{{ taxPay.successTotalAmount }} </span>元</span>
			</div>
			<div class="pl-total">
			 <span class="total-ze">识别失败：<span class="total-zeee">{{ taxPay.errorSize }} </span>人</span>
			 <span style="margin-left: 20px;" class="total-ze">总金额：<span class="total-zeee">{{ taxPay.errorAmont }} </span>元</span>
			</div>
			<div class="search">
				<span style="color: red;">{{taxPay.errorStr}}</span>
			</div>
			<div class="search">
				<span>任务成果<span style="color: red;">*</span>：</span>
				<div>
					<a-upload
					    :file-list="taxPayResult.fileList"
					    :multiple="true"
							name="file"
					    :action="taxPayResult.uploadUrl"
					    :headers="headers"
							:data="taxPayResult.uploadData"
					    @change="handleChangeVoutherFile"
					  >
					    <a-button>
					      <upload-outlined></upload-outlined>
					      点击选择任务成果文件，可多选
					    </a-button>
					  </a-upload>
				</div>
			</div>
		  
		  <div style="text-align: center;margin: 20px 0 20px 0;">
		    <a-button @click="taxPayModal = false">取消</a-button>
		    <a-button
		      type="primary"
		      style="margin-left: 10px"
		      :loading="taxPay.submitLoading"
		      @click="taxPaySubmit"
		      >确认</a-button
		    >
		  </div>
		</a-modal>
		
	</div>
</template>
<script>
import STable from '@/components/table/'
import Vue from "vue";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import {
  getOrderList,
	getAllTask,
	taxPaySubmit,
	getSysRole,
} from '@/api/api'
import moment from 'moment'
export default {
  components: {
    STable
  },
  data () {
    return {
      raskColumns: [
        {
          title: '创建时间',
          dataIndex: 'createTime',
          defaultSortOrder: 'descend',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix(),
          
        },
        {
          title: '任务名称',
          dataIndex: 'title',
        },
        
        {
          title: '付款人数',
          dataIndex: 'payNum',
        },
        {
          title: '付款金额',
          dataIndex: 'payAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '费用金额',
          dataIndex: 'payFeeAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '补缴金额',
          dataIndex: 'supplementAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '总金额',
          dataIndex: 'payTotalAmount',
          customRender: text => text === null?'--':text.toFixed(2)
        },
        {
          title: '付款状态',
          dataIndex: 'payStatus',
          scopedSlots: { customRender: 'payStatus' },
        },
        // {
        //   title: '当前状态',
        //   dataIndex: 'payStatus',
        // },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ],
      pagenation: {
        showSizeChanger: true,
        pageNo: 1,
        pageSize: 10
      },
      queryParams: {
        title: ''
      },
			taxPayPermission: false,
			taxPayModal: false,
			headers: { "X-Access-Token": Vue.ls.get(ACCESS_TOKEN) },
			taxPay: {
				taskId: "",
				submitLoading: false,
				fileList: [],
				loading: false,
				uploadUrl: window._CONFIG["domianURL"]+"/slash-youth/client/b/taxPay/importTaxWorker",
				taskList: [],
				successSize: 0,
				successAmount:0,
				successFeeAmount:0,
				successTotalAmount: 0,
				successList:[],
				errorSize: 0,
				errorAmont: 0,
				errorStr:'',
				startDate:'',
				endDate:'',
				data: {
					taskId: "",
				},
				
			},
			taxPayResult: {
				fileList: [],
				uploadUrl: window._CONFIG["domianURL"] + "/api/tax/common/file/upload",
				uploadData: {
				  fileType: "103206"
				},
			}
    }
  },
	created(){
		this.loadAllTask();
		getSysRole("60").then((res) => {
		  if (res.success && res.result) {
		    this.taxPayPermission = true;
		  }
		});
	},
  methods: {
		taskSelect(val){
			// console.log(val)
			this.taxPay.taskId = val;
			this.taxPay.data.taskId = val;
		},
		loadAllTask(){
			getAllTask().then(res => {
				// console.log(this.taxPay)
				this.taxPay.taskList = res.result;
				
			})
		},
		handleChangeFile(info){
			this.taxPay.loading = true;
			this.taxPay.fileList = info.fileList;
			if (info.file.status === "done") {
			  this.taxPay.loading = false;
				// console.log(info.file.response)
			  if (info.file.response.success) {
					let d = info.file.response.result;
					this.taxPay.successSize = d.successCount;
					this.taxPay.successAmount = d.successAmount;
					this.taxPay.successFeeAmount = d.successFeeAmount;
					this.taxPay.successTotalAmount = d.successTotalAmount;
					this.taxPay.successList = d.successMap;
					this.taxPay.errorSize = d.errorCount;
					this.taxPay.errorAmont = d.errorAmount;
					if(d.errorCount > 0){
						let errorStr = '';
						d.errorMap.forEach((item) => {
							errorStr += item.userName+"-"+item.idCard+"("+item.errorMsg+")\n"
						});
						this.taxPay.errorStr = errorStr;
					}
			  } else {
			    this.$message.error(info.file.response.message);
			  }
			}
		},
		handleChangeVoutherFile(info){
			this.taxPayResult.fileList = info.fileList;
			// console.log(info.file)
			if (info.file.status === "done") {
				// console.log(info.file.response)
			  if (info.file.response.success) {
					// console.log(this.taxPayResult.fileList)
			  } else {
			    this.$message.error(info.file.response.message);
			  }
			}
		},
		taxPaySubmit(){
			
			let params = {};
			params.taskId = this.taxPay.taskId;
			params.workerList = this.taxPay.successList;
			let result = [];
			this.taxPayResult.fileList.forEach((item) => {
				let o = {};
				o.name = item.name;
				o.url = 'https://oss.zfycloud.com/'+item.response.result;
				o.type = item.type;
				result.push(o);
			});
			params.resultList = result;
			params.startDate = this.taxPay.startDate;
			params.endDate = this.taxPay.endDate;
			if(!params.taskId){
				this.$message.error("请选择任务");
				return;
			}
			if(!params.startDate || !params.endDate){
				this.$message.error("请选择结算周期");
				return;
			}
			if(params.workerList.length <= 0){
				this.$message.error("名单不允许为空");
				return;
			}
			if(params.resultList.length <= 0){
				this.$message.error("请上传任务成果");
				return;
			}
			this.taxPay.submitLoading = true;
			taxPaySubmit(params).then(res => {
				if(res.success){
					// 
					window.localStorage.setItem('submenu','/settlementDetail');
					window.localStorage.setItem('taskInfo',JSON.stringify(res.result));
					this.$router.push('/settlementDetail');
				}else{
					this.$message.error(res.msg ? res.msg : res.message);
				}
				this.taxPay.submitLoading = false;
			})
		},
		onChangeDate(date, dateString) {
		  // console.log(date, dateString);
		  this.taxPay.startDate = dateString[0];
		  this.taxPay.endDate = dateString[1];
		},
    // 获取订单列表
    raskData (parameter) {
      return getOrderList(Object.assign(parameter,this.queryParams,{listType: 1})).then(res => {
        return res.result
      })
    },
    // 查询
    handleToSearchEnterprise () {
      this.$refs.raskTable.refresh(true)
    },
    // 重置
    resetSearchEnterprise () {
      this.queryParams = {
        title: ''
      }
      this.handleToSearchEnterprise()
    },
    // 表格改变
    onRaskChange (pagination, filters, sorter) {
      // console.log('params', pagination, filters, sorter)
    },
    // 审核
    cancelRask (record) {},
    // 复核
    fuheRask (record) {},
    // 详情
    raskDetail (record) {
      window.localStorage.setItem('submenu','/settlementDetail')
      window.localStorage.setItem('taskInfo',JSON.stringify(record))
      this.$router.push('/settlementDetail')
    },
  }
}
</script>
<style scoped lang="less">
.title{
  font-size: 24px;
  color: #202020;
  margin-bottom: 26px;
}
.search{
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.table-search-submitButtons{
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 30px;
}
.table-point{
  display: flex;
  align-items: center;
  // justify-content: center;
  span{
    width: 4px;
    height: 4px;
    background: #1890FF;
    border-radius: 50%;
    margin-right: 4px;
  }
}
.pl-table {
  border: 1px solid #dadada;
  border-radius: 6px;
  margin: 15px 0 0 0;
}
.pl-total {
  margin: 10px 0 30px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .total-ze {
    color: #33383e;
    font-size: 16px;
    // margin: 15px 15px 0 0;
    .total-zee {
      color: #ff4d4f;
      font-size: 24px;
    }
    .total-zeee {
      color: #ff4d4f;
      font-size: 16px;
    }
  }
}
</style>