/*
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-04-16 12:48:36
 * @LastEditTime: 2021-07-13 14:56:21
 */
import router from './router'
import Vue from 'vue'
import { ACCESS_TOKEN } from "@/store/mutation-types"

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = '分时领英-' + to.meta.title
    let token = Vue.ls.get(ACCESS_TOKEN)
    if (to.path==='/login' || to.path === '/' || to.path === '/contract' ) {
      next()
    } else {
      if (token) {
        next()
      } else {
        next({path:'/login'})
      }
    }
  }
})