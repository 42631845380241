<template>
  <div class="container">
    <div class="header">
      <div class="header-left">
        <div>
          <img :src="logoInfo.logo" alt="" />
        </div>
        <!-- <div class="img"></div>
        <div class="name">
          <div class="name">智赋云</div>
          <div class="eg-name">zfycloud</div>
        </div> -->
        <div class="line"></div>
        <div class="qy-center">分时领英</div>
      </div>
      <div class="right-option">
        <div class="tips-doc" @click="tipsDoc">
          <span class="txt">操作指南</span>
        </div>
        <div class="tips-doc">
          <a-popover placement="bottomRight">
            <template slot="title">
              <span v-if="userRoleType == '102001'">当前角色：管理员</span>
              <span v-if="userRoleType == '102011'">当前角色：普通用户</span>
            </template>
            <template slot="content">
              <p>管理员权限：审核、支付结算单</p>
              <p>普通用户权限：发布任务、审核任务、提交结算单</p>
            </template>
            <span class="txt">账户权限</span>
            <!-- <img src="../assets/tip_7D8292.png" style="width: 23px;" alt="提示"> -->
          </a-popover>
          <span class="txt"></span>
        </div>
        <div class="header-right">
          <div class="header-right-title">
            {{ userInfo.realname.slice(0, 1) }}
          </div>
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <div class="header-right">
                <div class="header-right-name">{{ userInfo.realname }}</div>
              </div>
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="toPersonCenter">用户中心</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="logout">注销</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <a-menu
          mode="inline"
          :open-keys="openKeys"
          style="width: 280px"
          @click="selectIt"
          v-if="isRefresh"
        >
          <!-- <a-menu-item :class="active==='operationManage'?'ant-menu-item-selected':''" key="operationManage">
            <img src="../assets/operationManage.png" class="img" alt=""> 知识库
          </a-menu-item> -->
          <a-menu-item
            :class="
              active === 'raskManage' ||
              active === '/raskAdd' ||
              active === '/raskDetail' ||
              active === '/raskDetailMany' ||
              active === '/raskDetailNew' ||
              active === '/raskDetailManyNew' ||
              active === '/settlementDetailCheck'
                ? 'ant-menu-item-selected'
                : ''
            "
            key="raskManage"
          >
            <img src="../assets/raskManage.png" class="img" alt="" />
            <span style="margin-left: -3px">任务管理</span>
          </a-menu-item>
          <a-menu-item
            :class="
              active === 'userRecord' || active === '/userRecord'
                ? 'ant-menu-item-selected'
                : ''
            "
            key="userRecord"
          >
            <img src="../assets/raskManage.png" class="img" alt="" />
            <span style="margin-left: -3px">人员管理</span>
          </a-menu-item>
          <a-sub-menu key="sub1">
            <span slot="title"
              ><span>
                <img
                  src="../assets/menu_01.png"
                  class="img"
                  alt=""
                />财务中心</span
              ></span
            >
            <a-menu-item
              :class="
                active === 'settlementManage' ? 'ant-menu-item-selected' : ''
              "
              key="settlementManage"
            >
              付款管理
            </a-menu-item>
            <a-menu-item
              :class="
                active === 'rechargeManage' ? 'ant-menu-item-selected' : ''
              "
              key="rechargeManage"
            >
              充值管理
            </a-menu-item>
            <a-menu-item
              :class="active === 'settleData' ? 'ant-menu-item-selected' : ''"
              key="settleData"
            >
              结算数据
            </a-menu-item>
            <a-menu-item
              :class="
                active === 'taxPaymentReceipt' ? 'ant-menu-item-selected' : ''
              "
              key="taxPaymentReceipt"
            >
              完税凭证
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="sub2">
            <span slot="title"
              ><span>
                <img
                  src="../assets/menu_02.png"
                  class="img"
                  alt=""
                />企业发票</span
              ></span
            >
            <a-menu-item
              key="kkfp"
              :class="active === 'kkfp' ? 'ant-menu-item-selected' : ''"
            >
              可开发票
            </a-menu-item>
            <a-menu-item
              key="fpxx"
              :class="active === 'fpxx' ? 'ant-menu-item-selected' : ''"
            >
              发票信息
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
      <div class="right" v-if="bankInfo.bankNo == '4402279009100110684'">
        <div class="waring_label_default">
          客户，您好：我司的资金转入账户为：4402279009100110684（工商银行），请注意核对账户信息。点击
          <span @click="jumpRechargeManage">立即查看</span>
        </div>
        <div
          style="
            background: #ffffff;
            padding: 28px 34px;
            border-radius: 4px;
            width: 100%;
          "
        >
          <router-view />
        </div>
      </div>
      <div class="rights" v-else>
        <div style="padding: 28px 34px; border-radius: 4px">
          <router-view />
        </div>
      </div>
    </div>
    <a-modal
      title="提示"
      :visible="phoneAndEmailVisible"
      @ok="handlePhoneAndEmailSubmit"
      @cancel="phoneAndEmailVisible = false"
    >
      <div>
        系统监测到您还没有验证手机号，可能会影响后续的支付，是否需要立即验证手机号？
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Vue from "vue";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import settlementVue from "../views/settlementManage/settlement.vue";
import {
  getEnterpriseChannelConfig,
  getPhoneAndeEmail,
  getEnterprisePayConfig,
} from "@/api/api";
import watermark from "watermark-dom";

export default {
  name: "Layout",
  data() {
    return {
      active: "raskManage",
      rootSubmenuKeys: ["sub1", "sub2", "sub4"],
      openKeys: ["sub1", "sub2"],
      userInfo: null,
      logoInfo: {},
      userRoleType: "",
      phoneAndEmailVisible: false, // 验证手机号的弹窗提示
      isRefresh: true,
      bankInfo: {},
    };
  },
  // 刷新记住上次操作页面
  created() {
    this.userRoleType = Vue.ls.get("USER_ROLE_TYPE");
    let menu = window.localStorage.getItem("submenu");
    if (menu) {
      this.active = menu;
      if (menu === "raskManage") {
        this.$router.push("/rask");
      } else if (menu === "settlementManage") {
        this.active = "settlementManage";
        this.$router.push("/settlement");
      } else if (menu === "kkfp") {
        this.$router.push("/ticket");
      } else if (menu === "fpxx") {
        this.$router.push("/ticketInfo");
      } else if (menu === "/settlementDetail") {
        this.active = "settlementManage";
        this.$router.push("/settlementDetail");
      } else if (menu === "/ticketDetail") {
        this.active = "kkfp";
      } else if (menu === "/ticketSure") {
        this.active = "kkfp";
      } else if (menu === "/ticketInfo") {
        this.active = "fpxx";
      } else if (menu === "rechargeManage") {
        this.active = "rechargeManage";
      } else if (menu === "userRecord") {
        this.active = "userRecord";
      } else if (menu === "settleData") {
        this.active = "settleData";
      } else if (menu === "taxPaymentReceipt") {
        this.active = "taxPaymentReceipt";
      } else {
        this.$router.push(menu);
      }
    } else {
      this.active = "raskManage";
    }
    this.userInfo = Vue.ls.get("Login_Userinfo");

    this.getLogoInfo();

    this.getBindInfo();

    this.getPayConfig();
  },
  methods: {
    ...mapActions(["Login", "WxLogin", "Logout"]),
    // alert位置的点击查看跳转到重置管理页面
    jumpRechargeManage() {
      this.selectIt({ key: "rechargeManage/tips" });
    },
    logout() {
      this.Logout().then(() => {
        window.localStorage.clear();
        watermark.init({
          watermark_txt: " ", // watermark.remove()无效  重新设置内容使页面水印清空
        });
        this.$router.push("/login");
      });
    },
    tipsDoc() {
      window.open(
        // "https://tax-trade.oss-cn-chengdu.aliyuncs.com/template/fsly_operation_manual.pdf"
        "https://tax-trade.oss-cn-chengdu.aliyuncs.com/template/czsp/%E7%B3%BB%E7%BB%9F%E6%93%8D%E4%BD%9C%E6%95%99%E7%A8%8Bv3.m4v"
      );
    },
    // 前往个人中心
    toPersonCenter() {
      let token = Vue.ls.get(ACCESS_TOKEN);
      window._CONFIG["personCenterURL"] = "https://usercenter.zfycloud.com";
      // window._CONFIG["personCenterURL"] = "http://192.168.0.96:5500";
      // window.location.href =
      //   window._CONFIG["personCenterURL"] + "/#/baseInfo?token=" + token;
      window.open(
        window._CONFIG["personCenterURL"] + "/#/baseInfo?token=" + token
      );
    },
    // submenu改变
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    // 选择submenu时跳转至相对应页面
    selectIt(value) {
      if (value.key === "rechargeManage/tips") {
        this.active = "rechargeManage";
        window.localStorage.setItem("submenu", "rechargeManage");
        this.isRefresh = false;
        this.$nextTick(() => {
          this.isRefresh = true;
        });
      } else {
        window.localStorage.setItem("submenu", value.key);
        this.active = value.key;
      }
      console.log(this.active);
      if (value.key === "raskManage") {
        this.$router.push("/rask");
      } else if (value.key === "operationManage") {
        this.$router.push("/operation");
      } else if (value.key === "settlementManage") {
        this.$router.push("/settlement");
      } else if (value.key === "kkfp") {
        this.$router.push("/ticket");
      } else if (value.key === "fpxx") {
        this.$router.push("/ticketInfo");
      } else if (value.key === "rechargeManage") {
        this.$router.push("/rechargeManage");
      } else if (value.key === "rechargeManage/tips") {
        this.$router.push("/rechargeManage/tips");
      } else if (value.key === "userRecord") {
        this.$router.push("/userRecord");
      } else if (value.key === "settleData") {
        this.$router.push("/settleData");
      } else if (value.key === "taxPaymentReceipt") {
        this.$router.push("/taxPaymentReceipt");
      }
    },
    getLogoInfo() {
      getEnterpriseChannelConfig(this.userInfo.taxUserId).then((res) => {
        if (res.success && res.result) {
          this.logoInfo = res.result;
        }
      });
    },
    // 查询企业绑定手机号邮箱
    getBindInfo() {
      getPhoneAndeEmail().then((res) => {
        if (res.result.phoneCheck === 0) {
          this.phoneAndEmailVisible = true;
        }
      });
    },
    // 验证手机号的信息提示弹窗的确认按钮
    handlePhoneAndEmailSubmit() {
      let token = Vue.ls.get(ACCESS_TOKEN);
      // window._CONFIG["personCenterURL"] = "http://192.168.0.96:5500";
      window._CONFIG["personCenterURL"] = "https://usercenter.zfycloud.com";
      window.open(
        window._CONFIG["personCenterURL"] + "/#/accountInfo?token=" + token
      );
      // window.location.href =
      //   window._CONFIG["personCenterURL"] + "/#/accountInfo?token=" + token;
    },
    //获取收款账号
    getPayConfig() {
      getEnterprisePayConfig("103101").then((res) => {
        if (res.success && res.result) {
          this.bankInfo = res.result;
        }
      });
    },
  },
  mounted() {
    console.log("22");
    watermark.init({
      watermark_txt: this.userInfo.realname, // 水印的内容
      watermark_x_space: 120, // 水印x轴间隔
      watermark_y_space: 120, // 水印y轴间隔
      watermark_font: "Vedana", // 水印字体
      watermark_fontsize: "15px", // 水印字体大小
      watermark_alpha: 0.2, // 水印透明度，要求设置在大于等于0.005
      watermark_angle: 18, // 水印倾斜度数
      watermark_width: 150, //水印宽度
    });
  },
};
</script>
<style lang="less" scoped>
.right-option {
  display: flex;

  .tips-doc {
    display: flex;
    align-items: center;

    .txt {
      font-size: 12px;
      color: #7d8292;
      padding-right: 30px;
      padding-left: 7px;
      cursor: pointer;
    }
  }
}

.container {
  width: 100%;
  // overflow: hidden;
}

.img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  vertical-align: center;
}

.header {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  border-bottom: 1px solid #e3e4e7;

  .header-left {
    display: flex;
    align-items: center;

    img {
      // width: 52px;
      height: 36px;
      margin-right: 7px;
    }

    .name {
      font-size: 21px;
      font-family: "FZZhengHeiS-M-GB";
      color: #202020;
    }

    .eg-name {
      font-size: 8px;
      font-family: "FZZhengHeiS-M-GB";
      font-weight: 400;
      color: #202020;
      letter-spacing: 2px;
    }

    .line {
      width: 1px;
      height: 30px;
      background: #ababab;
      margin: 0 5px;
    }

    .qy-center {
      font-size: 16px;
      color: #202020;
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .header-right-title {
      width: 30px;
      height: 30px;
      background: #6379f5;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      color: #ffffff;
      margin-right: 12px;
    }

    .header-right-name {
      // width: 143px;
      color: #202020;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.content {
  box-sizing: border-box;
  display: flex;
  width: 100%;

  .left {
    width: 280px;
    height: 969px;
    padding: 40px 0;
    border-right: 1px solid #e3e4e7;

    ul {
      padding: 0;
      list-style: none;

      li {
        border-left: 3px solid transparent;
        width: 280px;
        box-sizing: border-box;
        padding: 15px 0 15px 47px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }

      cursor: pointer;

      .active {
        background: #e6f7ff;
        border-left: 3px solid #2878ff;
        color: rgba(40, 120, 255, 0.85);
      }
    }
  }

  .right {
    // width: 1623px;

    // width: 100%;
    flex: auto;
    min-height: 969px;
    padding: 24px;
    background: #f6f6f6;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  .waring_label_default {
    box-sizing: border-box;
    margin: 0 0 15px 0;
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    padding: 8px 15px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    word-wrap: break-word;
    border-radius: 4px;
    span {
      color: #1890ff;
      cursor: pointer;
    }
  }

  // @media screen and (max-width:1400px) {
  //   .right{
  //     width: 1120px;
  //   }
  // }
  //   @media screen and (max-width:1500px) and (min-width: 1401px){
  //   .right{
  //     width: 1220px;
  //   }
  // }
  //   @media screen and (max-width:1600px)  and (min-width: 1501px){
  //   .right{
  //     width: 1200px;
  //   }
  // }
  //   @media screen and (max-width:1700px)  and (min-width: 1601px){
  //   .right{
  //     width: 1300px;
  //   }
  // }
  .rights {
    width: 100%;
    height: 969px;
    padding: 24px;
    background: #f6f6f6;
    flex: 1;
  }
}

.content .left ul .ant-menu-item {
  padding: 0 0 0 24px;
}

.content .left ul .ant-menu-submenu {
  padding: 0 1px 0 0px;
}

.content .left ul .ant-menu-submenu .ant-menu-item {
  width: 278px;
  margin-left: -3px;
}

.content .left ul .ant-menu-item-selected {
  border-left: 3px solid #2878ff;
  color: rgba(0, 0, 0, 0.85);
  border-right: 0;
  background: #edeff3;

  &:after {
    display: none;
  }
}
</style>
<style>
.ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow {
  display: none !important;
}

.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
</style>
