import { getAction,deleteAction,putAction,postAction} from '@/api/manage';
import { axios } from '@/utils/request'

const queryPermissionsByUser = (params)=>getAction("/api/sys/permission/getUserPermissionByToken",params);

// 查询完税凭证
const getVoucherList = (params) => getAction('/api/tax/client/enterprise/voucher/list',params)
// 查询系统字典
const getSysDictItem = (params) => getAction('/api/client/sys/getSysDictItem',params)
// 获取任务列表
const getRaskList = (params) => getAction('/slash-youth/client/b/task/list',params)
// 新建任务
const raskListAdd = (params) => postAction('/slash-youth/client/b/task/pushTask',params)
// 获取任务类型
const getRaskType = (params) => getAction('/api/tax/client/enterprise/trade/task/getTaskType2',params)
// 取消任务
const raskCancel = (params) => putAction(`/slash-youth/client/b/task/cancelTask/${params}`)
// 完结任务
const taskOver = (params) => postAction(`/slash-youth/client/b/task/overTask/${params}`)
// 结束任务
const raskEnd = (params) => putAction(`/slash-youth/client/b/task/endApply/${params}`)
// 任务详情
const raskDetail = (params) => getAction(`/slash-youth/client/b/task/queryById`, params)
// 获取任务工作者
const getWorkList = (params) => getAction('/slash-youth/client/b/taskWorker/list',params)
// 签约
const raskAssign = (params) => postAction('/slash-youth/client/b/taskWorker/confirmApply',params)
// 拒绝签约
const refuseAssign = (params) => postAction('/slash-youth/client/b/taskWorker/refuseConfirmed',params)
// 取消签约
const cancelAssign = (params) => postAction('/slash-youth/client/b/taskWorker/cancelSign',params)
// 获取任务类别名称
const getRaskTypeName = (params) => getAction(`/api/tax/client/enterprise/trade/task/getTaskTypeById/${params}`)
// 获取预付款尾款列表
const taskWorkerPayItem = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/list',params)
// 获取统计预付款尾款
const settlementStatistical = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/settlementStatistical',params)
// 获取结算单详情
const getPayDetails = (params) => getAction('/slash-youth/client/b/taskWorkerPay/getPayDetails',params)
// 提交任务订单
const taskWorkerPaySubmit = (params) => postAction('/slash-youth/client/b/taskWorkerPay/submit',params)
// 获取任务审核列表
const getByWorkerIds = (params) => getAction(`/slash-youth/client/b/taskWorkerResult/queryPageList`,params)
// 审核任务
const taskWorkerResult = (params) => postAction('/slash-youth/client/b/taskWorkerResult/audit',params)

// 结算单
// 获取结算单列表
const getOrderList = (params) => getAction('/slash-youth/client/b/taskWorkerPay/list',params)
// 复核
const taskWorkerPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/review',params)
// 获取账户余额
const getBalance = (params) => getAction(`/api/tax/client/user/wallet/getWalletByType/${params}`)
// 预支付
const prePay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/prePay',params)
// 支付
const taskPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/pay',params)
// 获取发票列表
const ticketList = (params) => getAction('/slash-youth/client/b/task/confirmInvoiceList',params)
// 查询开票信息
const ticketInfos = (params) => getAction('/api/tax/client/invoice/getInvoiceTradesInfo',params)
// 查询收件人信息
const getInvoiceMailed = () => getAction('/api/tax/client/enterprise/receipt/getInvoiceMailed')
// 提交开票
const submitTicket = (params) => postAction('/slash-youth/client/b/task/confirmInvoice',params)
// 查看历史开票
const getApplyInvoiceTradeList = (params) => getAction('/api/tax/admin/enterprise/invoice/trade/getApplyInvoiceTradeList',params)
// 获取发票详情
const getApplyInvoiceTradeDetails = (params) => getAction(`/api/tax/admin/enterprise/invoice/trade/getApplyInvoiceTradeDetails/${params}`)
// 获取发票内容
const getInvoiceByIndustryId = (params) => getAction(`/api/tax/client/invoice/getInvoiceByIndustryId/${params}`)
// 获取邮箱
const getUserBindEmail = () => getAction('/api/tax/client/user/getUserBindEmail')
// 查询邀请人员
const getUserByPhone = (params) => getAction(`/api/tax/admin/user/getUserByPhone/${params}`)
// 邀请
const invitation = (params) => postAction('/slash-youth/client/b/taskWorker/invitation',params)
// 重置密碼
const resetPwd = (params) => postAction('/api/tax/client/user/payPassword/forget',params)
// 手机号发送短信
const sendInvitationSms = (params) => getAction(`/slash-youth/client/b/taskWorker/sendInvitationSms/${params}`)
// 推荐优质人才列表
const getRandomRecommendUser = () => getAction('/api/tax/admin/user/getRandomRecommendUser')
// 检验是否已报名
const checkRepetition = (params) => getAction('/slash-youth/client/b/taskWorker/checkRepetition',params)
const invitationRecommendUser = (params) => postAction('/slash-youth/client/b/taskWorker/invitationRecommendUser',params)
// 获取用户假数据
const getUserData = (params) => getAction('/api/client/sys/getUserData',params)
// getEnterpriseTradeDetaileds
const getEnterpriseTradeDetaileds = (params) => getAction('/api/tax/client/enterprise/trade/queryEnterpriseTradeDetaileds',params)
// 查询当前用户角色
const getRoleType = (params) => getAction('/api/tax/client/enterprise/trade/getRoleType',params)
// 批量邀请
const batchInvitationRecommendUser = (params) => postAction('/slash-youth/client/b/taskWorker/batchInvitationRecommendUser',params);
// 获取费率
const getServiceRule = (params) => getAction('/api/tax/client/enterprise/sign/getServiceRule',params);
// 获取费率
const getFeePoint = (params) => getAction('/api/tax/client/enterprise/sign/getFeePoint',params);
// 检查是否绑定微信
const checkBindWxMq = (params) => getAction('/api/tax/client/user/checkBindWxMq',params);
// 查询批量上传工作者列表
const queryImportWorkerList = (params) => getAction('/slash-youth/client/b/task/queryImportWorkerList',params)
// 提交批量上传工作者列表
const submitApply = (params) => postAction('/slash-youth/client/b/task/submitApply2',params);
// 查询批量上传工作者列表汇总
const importWorkerStatistics = (params) => getAction(`/slash-youth/client/b/task/importWorkerStatistics/${params}`)
// 获取银行卡号
const getBanknum = (params) => getAction('/api/tax/client/user/wallet/recharge/getRechargeInfo/bank',params);
// 获取充值记录
const getRecharges = (params) => getAction('/api/tax/client/user/wallet/recharge/getRecharges2',params);

//查询结算记录
const getSettles = (params) => getAction('/api/tax/client/user/settle/getSettlement',params);

// 获取账户余额
const getRechargesAccount = (params) => getAction('/api/tax/client/user/wallet/getWalletByType/bank',params);
// 获取支付宝账户余额
const getRechargesAccountAlipay = (params) => getAction('/api/tax/client/user/wallet/getWalletByType/alipay',params);
// 添加周期
const addPeriodPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/addPeriodPay',params);
// 添加周期
const taskWorkerPayItemList = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/list',params);
// 取消付款
const cancelPay = (params) => postAction(`/slash-youth/client/b/taskWorkerPayItem/cancelPay?id=${params}`);
// 提交结算
const submitPeriodPay = (params) => postAction(`/slash-youth/client/b/taskWorkerPay/submitPeriodPay`,params);
// 提交结算
const taskWorkerPayList = (params) => getAction(`/slash-youth/client/b/taskWorkerPay/list`,params);
// 确认申请发票
const confirmInvoice = (params) => postAction(`/slash-youth/client/b/taskWorkerPay/confirmInvoice`,params);
// 已开发票
const invoiceList = (params) => getAction(`/slash-youth/client/b/taskWorkerPay/invoiceList`,params);
// 关闭任务
const fulFilTask = (params) => postAction(`/slash-youth/client/b/task/fulFilTask/${params}`);
// 修改任务
const tastEdit = (params) => postAction('/slash-youth/client/b/task/edit',params);
// 查询白名单
const isWhiteList = (params) => getAction('/slash-youth/client/b/task/isWhiteList',params);
// 上传任务结果
const enterpriseAdd = (params) => postAction('/slash-youth/client/b/taskWorkerResult/enterpriseAdd',params);
const enterpriseUnifyAdd2 = (params) => postAction('/slash-youth/client/b/taskWorkerResult/enterpriseUnifyAdd2',params);
const enterpriseUnifyAdd = (params) => postAction('/slash-youth/client/b/taskWorkerResult/enterpriseUnifyAdd',params);


//通过电话号码加入邀请列表
const phoneInvitation = (params) => postAction('/slash-youth/client/b/task/phoneInvitation',params);

//批量上传工作者(多次结算)
const importWorkerMorePay = (params) => postAction('/slash-youth/client/b/task/importWorkerMorePay',params);
//批量上传工作者(单次结算)
const importWorkerOnePay = (params) => postAction('/slash-youth/client/b/task/importWorkerOnePay',params);

//审核任务
const auditByPayItemId = (params) => postAction('/slash-youth/client/b/taskWorkerResult/auditByPayItemId',params);

//查询任务工作结果
const getResultNew = (params) => getAction('/slash-youth/channel/task/getResult/'+ params);


//单次任务提交结算
const onePaySubmitSettlement = (params) => postAction('/slash-youth/client/b/taskWorkerPay/onePaySubmitSettlement',params);

//获取确认人数和待确认人数

const getUserCount = (params) => getAction('/slash-youth/client/b/taskWorker/getUserCount/'+ params);

//交易任务单-任务类别
const getTaskType3 = (params) => getAction('/api/tax/client/enterprise/trade/task/getTaskType3' , params);

//查询补单详情
const queryEnterpriseTradeSupplementDetaileds = (params) => getAction('/api/tax/client/enterprise/trade/queryEnterpriseTradeSupplementDetaileds' , params);

//查询补缴金额
const getSupplementAmount = (params) => getAction('/api/tax/client/enterprise/trade/getSupplementAmount/'+ params);

//查询渠道企业logo配置等
const getEnterpriseChannelConfig = (params) => getAction('/api/tax/admin/enterprise/getEnterpriseChannelConfig/'+ params);

// 查询企业绑定手机号邮箱
const getPhoneAndeEmail = () => getAction('/api/tax/client/enterprise/getBindInfo');

//查询企业logo
const getEnterpriseLogo = (params) => getAction('/api/tax/admin/enterprise/getEnterpriseLogo/'+ params);

//查询企业付款账户信息
const getEnterprisePayConfig = (params) => getAction('api/tax/client/enterprise/getEnterprisePayConfig/'+ params);

//批量上传结算
const importPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/importPay',params);
//批量导入结算列表
const getImportPayList = (params) => getAction('/slash-youth/client/b/taskWorkerPay/importPayList', params);
//批量上传结算统计
const getImportPayStatistics = (params) => getAction('/slash-youth/client/b/taskWorkerPay/importPayStatistics/' + params);
//提交批量上传结算
const submitImportPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/submitImportPay',params);


//获取支付验证码
const getPayChenkCode = (params) => getAction('/api/tax/client/enterprise/getPayChenkCode', params);
//企业支付效验方式
const getEnterprisePayCheckMode = (params) => getAction('/api/tax/client/enterprise/getEnterprisePayCheckMode', params);

//获取企业绑定手机号码
const getEnterpriseBindPhone = (params) => getAction('/api/tax/client/enterprise/getEnterpriseBindPhone', params);


//支付宝签约
const singAlipay = (params) => postAction('/api/tax/client/enterprise/singAlipay', params);
//查询是否开通支付宝
const queryAlipay = (params) => getAction('/api/tax/client/enterprise/queryAlipay', params);
//支付宝充值
const alipayRecharge = (params) => postAction('/api/tax/client/user/wallet/recharge/alipayRecharge', params);
//更新支付宝充值状态
const updateAlipayRecharge = (params) => postAction('/api/tax/client/user/wallet/recharge/updateAlipayRecharge', params);
//查询企业先票后款模式
const getFirstInvoiceAfterPay = (params) => getAction('/api/tax/client/enterprise/getFirstInvoiceAfterPay', params);
//查询是否用户端展示
const getTaskIsShow = (params) => getAction('/slash-youth/client/b/task/isShow', params);

//企业删除用工
const delWorker = (params) => postAction('/slash-youth/client/b/taskWorker/delWorker', params);


//申请发票列表2
const confirmInvoiceList2 = (params) => getAction('/slash-youth/client/b/task/confirmInvoiceList2', params);
//申请发票列表2
const invoiceList2 = (params) => getAction('/slash-youth/client/b/taskWorkerPay/invoiceList2', params);
//导出发票列表2

//查询分批次申请发票列表

const applyInvoiceList = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/applyInvoiceList', params);

//查询企业用户记录
const getEnterpriseUser = (params) => getAction('/api/tax/client/enterprise/user/getEnterpriseUser', params);

//导出企业用户
const exportEnterpriseUser = function downFile(parameter){
  return axios({
    url: '/api/tax/client/enterprise/user/exportEnterpriseUser',
    data: parameter,
    method:'post' ,
    responseType: 'blob'
  })
}


// 导出充值记录
const exprotRecharges = function downFile(parameter){
  return axios({
    url: '/api/tax/client/user/wallet/recharge/exprotRecharges',
    data: parameter,
    method:'post' ,
    responseType: 'blob'
  })
}

const exportInvoice = function downFile(parameter){
  return axios({
    url: '/slash-youth/client/b/taskWorkerPay/exportInvoice',
    data: parameter,
    method:'post' ,
    responseType: 'blob'
  })
}



//导出企业用户
const exportSettles = function downFile(parameter){
  return axios({
    url: '/api/tax/client/user/settle/exportSettles',
    data: parameter,
    method:'post' ,
    responseType: 'blob'
  })
}

//任务工作支付
const enterpriseUserPayList = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/enterpriseUserPayList', params);

//查询是否包含费用展示
const getIsShowIsIncludeFee = (params) => getAction('/slash-youth/client/b/task/isShowIsIncludeFee', params);

const getSysRole = (params) => getAction('/slash-youth/client/b/task/getSysRole/'+params);
//获取企业所有任务，下拉列表，label,value
const getAllTask = (params) => getAction('/slash-youth/client/b/taxPay/allTask');
//按名单支付提交付款
const taxPaySubmit = (params) => postAction('/slash-youth/client/b/taxPay/submit', params);
//导出签约用户

const exportWorker = function downFile(parameter){
  return axios({
    url: '/slash-youth/client/b/taskWorker/exportWorker',
    data: parameter,
    method:'post' ,
    responseType: 'blob'
  })
}



export {
  getVoucherList,
  getSysDictItem,
  enterpriseUnifyAdd,
  enterpriseUnifyAdd2,
  enterpriseAdd,
  isWhiteList,
  getFeePoint,
  tastEdit,
  fulFilTask,
  invoiceList,
  confirmInvoice,
  taskWorkerPayList,
  submitPeriodPay,
  cancelPay,
  taskWorkerPayItemList,
  addPeriodPay,
  getRechargesAccount,
  getRecharges,
  exprotRecharges,
  getSettles,
  exportSettles,
  getBanknum,
  importWorkerStatistics,
  submitApply,
  queryImportWorkerList,
  checkBindWxMq,
  getServiceRule,
  batchInvitationRecommendUser,
  getRoleType,
  getEnterpriseTradeDetaileds,
  getUserData,
  invitationRecommendUser,
  checkRepetition,
  getRandomRecommendUser,
  sendInvitationSms,
  resetPwd,
  invitation,
  getUserByPhone,
  getUserBindEmail,
  getInvoiceByIndustryId,
  getApplyInvoiceTradeDetails,
  getApplyInvoiceTradeList,
  getInvoiceMailed,
  submitTicket,
  ticketInfos,
  ticketList,
  taskPay,
  prePay,
  getBalance,
  taskWorkerPay,
  getOrderList,
  taskWorkerResult,
  getByWorkerIds,
  taskWorkerPaySubmit,
  getPayDetails,
  settlementStatistical,
  taskWorkerPayItem,
  getRaskTypeName,
  cancelAssign,
  refuseAssign,
  raskAssign,
  getWorkList,
  queryPermissionsByUser,
  getRaskList,
  raskListAdd,
  getRaskType,
  raskCancel,
  raskDetail,
  taskOver,
  raskEnd,
  phoneInvitation,
  importWorkerMorePay,
  importWorkerOnePay,
  auditByPayItemId,
  getResultNew,
  onePaySubmitSettlement,
  getUserCount,
  getTaskType3,
  queryEnterpriseTradeSupplementDetaileds,
  getSupplementAmount,
  getEnterpriseChannelConfig,
  getPhoneAndeEmail,
  getEnterpriseLogo,
  getEnterprisePayConfig,


  importPay,
  getImportPayList,
  getImportPayStatistics,
  submitImportPay,
  getEnterprisePayCheckMode,
  getPayChenkCode,
  getEnterpriseBindPhone,

  singAlipay,
  queryAlipay,
  alipayRecharge,
  updateAlipayRecharge,
  getRechargesAccountAlipay,
  getFirstInvoiceAfterPay,
  getTaskIsShow,
  delWorker,
  confirmInvoiceList2,
  invoiceList2,
  exportInvoice,
  applyInvoiceList,
  getEnterpriseUser,
  exportEnterpriseUser,
  enterpriseUserPayList,
  exportWorker,



  getIsShowIsIncludeFee,
	getSysRole,
	getAllTask,
	taxPaySubmit,
}