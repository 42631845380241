import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/components/Layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "引领企业数字化转型",
    },
    component: () => import("../views/login/index"),
    // component: () => import('../views/home/contract.vue')
  },
  {
    path: "/",
    name: "Home",
    meta: {
      title: "最便捷的雇佣方案提升企业工作效率，引领企业数字化转型",
    },
    component: () => import("../views/home/index"),
  },
  {
    path: "/contract",
    name: "Contract",
    meta: {
      title: "服务协议",
    },
    component: () => import("../views/home/contract.vue"),
  },
  // 操作规范
  {
    path: "/operation",
    meta: {
      title: "知识库",
    },
    component: Layout,
    children: [
      {
        path: "/operation",
        meta: {
          title: "知识库",
        },
        component: () => import("../views/operation/index"),
      },
    ],
  },
  {
    path: "/userRecord",
    meta: {
      title: "人员管理",
    },
    component: Layout,
    children: [
      {
        path: "/userRecord",
        meta: {
          title: "人员管理",
        },
        component: () => import("../views/userRecord/index.vue"),
      },
    ],
  },
  // 任务管理
  {
    path: "/rask",
    meta: {
      title: "任务管理",
    },
    component: Layout,
    children: [
      {
        path: "/rask",
        meta: {
          title: "任务管理",
        },
        component: () => import("../views/raskManage/rask"),
        // component: () => import('../views/raskManage/settlementDetail')
      },
      {
        path: "/raskAdd",
        meta: {
          title: "新增任务",
        },
        component: () => import("../views/raskManage/raskAdd"),
      },
      {
        path: "/raskDetail",
        name: "raskDetail",
        meta: {
          title: "新增详情",
        },
        component: () => import("../views/raskManage/raskDetail"),
      },
      {
        path: "/raskDetailMany",
        name: "raskDetailMany",
        meta: {
          title: "新增详情",
        },
        component: () => import("../views/raskManage/raskDetailMany"),
      },
      {
        path: "/raskDetailNew",
        name: "raskDetailNew",
        meta: {
          title: "新增详情",
        },
        component: () => import("../views/raskManage/raskDetailNew"),
      },
      {
        path: "/raskDetailManyNew",
        name: "raskDetailManyNew",
        meta: {
          title: "新增详情",
        },
        component: () => import("../views/raskManage/raskDetailManyNew"),
      },
      {
        path: "/settlementDetailCheck/:id",
        name: "settlementDetailCheck",
        meta: {
          title: "结算单详情",
        },
        component: () => import("../views/raskManage/settlementDetail"),
      },
    ],
  },
  // 开票管理
  {
    path: "/ticket",
    meta: {
      title: "开票管理",
    },
    component: Layout,
    children: [
      {
        path: "/ticket",
        meta: {
          title: "开票管理",
        },
        component: () => import("../views/ticketManage/ticket"),
      },
      {
        path: "/ticketDetail",
        meta: {
          title: "开票详情",
        },
        component: () => import("../views/ticketManage/ticketDetail"),
      },
      {
        path: "/ticketInfo",
        meta: {
          title: "发票信息",
        },
        component: () => import("../views/ticketManage/ticketInfo"),
      },
      {
        path: "/ticketSure",
        meta: {
          title: "确认发票",
        },
        component: () => import("../views/ticketManage/ticketSure"),
      },
    ],
  },
  // 结算管理
  {
    path: "/settlement",
    meta: {
      title: "付款管理",
    },
    component: Layout,
    children: [
      {
        path: "/settlement",
        meta: {
          title: "付款管理",
        },
        component: () => import("../views/settlementManage/settlement"),
      },
      {
        path: "/settlementDetail",
        meta: {
          title: "付款详情",
        },
        component: () => import("../views/settlementManage/settlementDetail"),
      },
      {
        path: "/rechargeManage/:isShowModal?",
        meta: {
          title: "充值管理",
        },
        component: () => import("../views/settlementManage/rechargeManage"),
      },
      {
        path: "/settleData",
        meta: {
          title: "结算数据",
        },
        component: () => import("../views/settlementManage/settleData"),
      },
      {
        path: "/taxPaymentReceipt",
        meta: {
          title: "完税凭证",
        },
        component: () => import("../views/settlementManage/taxPaymentReceipt"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
